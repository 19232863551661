import React, { useEffect } from 'react';
import { useState } from "@hookstate/core"
import hive from "./index"


function MobileFLoorMenu() {
  const { totalWidth, height, floorMenuOpened, mainColor, leftMenu, activeScene, activeOrbit } = useState(hive.state)
  leftMenu.get()

  return <div
    onClick={() => {
      if (floorMenuOpened.get()) {
        hive.toggleFloorMenu()
      }
    }}
    style={{
      width: totalWidth.get(),
      height: height.get(),
      position: "absolute",
      top: 0,
      left: 0,
      overflow: "hidden",
      pointerEvents: floorMenuOpened.get() ? "initial" : "none",
      zIndex: 4,
    }}>
    <div style={{
      background: "#0D0D0D",
      width: "100%",
      height: height.get() * 0.7,
      position: "absolute",
      top: floorMenuOpened.get() ? height.get() * 0.3 : height.get(),
      transition: 'top 0.3s',
      pointerEvents: "all",
      userSelect: "none",
    }}>
      <div
        onClick={() => { hive.toggleFloorMenu() }}
        style={{
          position: "absolute",
          width: height.get() * 0.12,
          height: height.get() * 0.12,
          lineHeight: height.get() * 0.12 + "px",
          textAlign: "center",
          // fontStyle: "bold",
          color: "#007AFF",
          left: totalWidth.get() - (height.get() * 0.12),
          fontSize: height.get() * 0.05,
          fontFamily: 'HelveticaNeue-Medium',
          cursor: "pointer",
        }}>
        OK
      </div>
      <div style={{
        width: "100%",
        height: height.get() * 0.12,
        lineHeight: height.get() * 0.12 + "px",
        background: "white",
        textAlign: "center",
        fontSize: height.get() * 0.05,
        color: `#${mainColor.get()}`,
        fontFamily: 'HelveticaNeue-Medium'
      }}>
        Sélectionnez un étage
      </div>
      <div style={{
        height: height.get() * 0.7 - height.get() * 0.12,
        overflowY: "scroll"
      }}>
        {leftMenu
          .map((item, i) =>
            <div
              key={i}
              onClick={(e) => {
                e.stopPropagation()
                hive.action(item.action.get(), item.target.get())
              }}
              style={{
                width: "100%",
                height: height.get() * 0.1,
                borderTop: (activeScene.get() === item.target.get() || activeOrbit.get() === item.target.get()) ? '1px solid #ABA9A2' : "none",
                borderBottom: (activeScene.get() === item.target.get() || activeOrbit.get() === item.target.get()) ? '1px solid #ABA9A2' : "none",
                textAlign: "center",
                lineHeight: height.get() * 0.1 + "px",
                fontSize: height.get() * 0.07,
                color: (activeScene.get() === item.target.get() || activeOrbit.get() === item.target.get()) ? "white" : "#9D9D9D",
                cursor: "pointer",
              }}>
              {item.text.get()}
            </div>
          )}
      </div>
    </div>
  </div>

}

export default MobileFLoorMenu;
